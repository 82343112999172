

import { defineComponent, reactive, ref, toRefs } from 'vue'
import CExportFile from '@/components/c-export-file'
import dayjs from 'dayjs'

export default defineComponent({
    components: {
        CExportFile
    },
    setup() {
        const queryForm = ref({
            acDate: '',
            type:1
        })
        const data = reactive({
            isFlag: true,
            fileTitle: ''
        })

        const change = () => {
            data.isFlag = false
            console.log(`聚富通对账${dayjs(queryForm.value.acDate).format('YYYY-MM-DD')}`)
            data.fileTitle = `${dayjs(queryForm.value.acDate).format('YYYY-MM-DD')}`
            queryForm.value.acDate = dayjs(queryForm.value.acDate).format('YYYY-MM-DD')
            setTimeout(()=>{
                data.isFlag = true
            },100)
        }
        return {
            ...toRefs(data),
            queryForm,
            change
        }
    },
    mounted() {
    }
})
